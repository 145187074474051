var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[(_vm.loading)?_c('Loading'):_vm._e(),_c('Navbar',{attrs:{"userData":_vm.users}}),_c('AddBtn'),_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row mt-1 justify-content-center"},[_c('div',{staticClass:"col col-md-7"},[_c('img',{staticClass:"mx-auto d-block",attrs:{"src":require("../assets/image/note.png"),"alt":"","width":"300"}}),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('p',{staticClass:"text-center"},[_vm._v("Plugin And Dependencies")]),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_c('p',{staticClass:"text-center"},[_vm._v("Follow Me In My Social Media")]),_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"mr-5",attrs:{"href":"https://facebook.com/gozenxs"}},[_c('b-icon',{attrs:{"icon":"facebook","scale":"2","variant":"secondary"}})],1),_c('a',{staticClass:"mr-5",attrs:{"href":"https://twitter.com/zenklot12"}},[_c('b-icon',{attrs:{"icon":"twitter","scale":"2","variant":"secondary"}})],1),_c('a',{staticClass:"mr-5",attrs:{"href":"https://instagram.com/zenklot"}},[_c('b-icon',{attrs:{"icon":"instagram","scale":"2","variant":"secondary"}})],1),_c('a',{staticClass:"mr-5",attrs:{"href":"https://www.linkedin.com/in/gozenx/"}},[_c('b-icon',{attrs:{"icon":"linkedin","scale":"2","variant":"secondary"}})],1),_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCiDXSqL_Rjv8wWKcx6suUzQ"}},[_c('b-icon',{attrs:{"icon":"youtube","scale":"2","variant":"secondary"}})],1)]),_vm._m(12),_vm._m(13)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col"},[_c('h2',[_vm._v("About "),_c('strong',[_vm._v("Zenk-note")])]),_c('hr')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"text-center mt-3 mb-5"},[_c('strong',[_vm._v("Welcome to zenknote")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"text-center mb-3"},[_c('strong',[_vm._v("Description")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center"},[_c('strong',[_vm._v("zenk-note")]),_vm._v(" is a simple notes application which is built on the basis of the "),_c('strong',[_vm._v("C")]),_vm._v("reate, "),_c('strong',[_vm._v("R")]),_vm._v("ead, "),_c('strong',[_vm._v("U")]),_vm._v("pdate, "),_c('strong',[_vm._v("D")]),_vm._v("elete process and authentication process with "),_c('strong',[_vm._v("JWT")]),_vm._v("."),_c('br'),_vm._v("This project is not completely finished. in the next update will be added search notes by title or tag and add the function change profile.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"text-center mt-5 mb-3"},[_c('strong',[_vm._v("Technology")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center"},[_vm._v("The technology used to build this application is the "),_c('strong',[_vm._v("backend")]),_vm._v(" using the "),_c('strong',[_vm._v("Golang")]),_vm._v(" programming language and the "),_c('strong',[_vm._v("frontend")]),_vm._v(" using javascript with the "),_c('strong',[_vm._v("vue.js")]),_vm._v(" framework.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"text-center mt-5 mb-3"},[_c('strong',[_vm._v("Technology Details")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-md-6"},[_c('h4',{staticClass:"text-center mb-5"},[_vm._v("Golang")]),_c('img',{staticClass:"img-fluid rounded mx-auto d-block mt-4 mb-3",attrs:{"src":require("../assets/image/golang.png"),"alt":"","width":"150"}}),_c('ul',{staticClass:"text-center mt-5"},[_c('li',[_vm._v("Gofiber")]),_c('li',[_vm._v("Gorm")]),_c('li',[_vm._v("BCrypto")]),_c('li',[_vm._v("Validator")]),_c('li',[_vm._v("Gomail")])]),_c('p',{staticClass:"text-center"},[_vm._v("For More Detail : "),_c('a',{attrs:{"href":"https://github.com/zenklot/backend-zenknote"}},[_vm._v("Github")])]),_c('p',{staticClass:"text-center"},[_vm._v("Don't forget to support me give me start on github")])]),_c('div',{staticClass:"col col-md-6"},[_c('h4',{staticClass:"text-center"},[_vm._v("Vue.js")]),_c('img',{staticClass:"img-fluid rounded mx-auto d-block",attrs:{"src":require("../assets/image/vue.png"),"alt":"","width":"150"}}),_c('ul',{staticClass:"text-center mt-3"},[_c('li',[_vm._v("Vue Router")]),_c('li',[_vm._v("Axios")]),_c('li',[_vm._v("Bootstrap-Vue")]),_c('li',[_vm._v("Vue-Sweetalert2")]),_c('li',[_vm._v("Vuelidate")])]),_c('p',{staticClass:"text-center"},[_vm._v("For More Detail : "),_c('a',{attrs:{"href":"https://github.com/zenklot/frontend-zenknote"}},[_vm._v("Github")])]),_c('p',{staticClass:"text-center"},[_vm._v("Don't forget to support me give me start on github")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"text-center mt-5 mb-3"},[_c('strong',[_vm._v("Thanks To")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center"},[_vm._v("in some css styles I'm using someone else's taken from "),_c('strong',[_vm._v("Codepen.io")]),_vm._v(". and I say thank you very much for the creator.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center"},[_c('strong',[_vm._v("Single Element CSS-Only Absolute Center Overlay Spinner")]),_vm._v(" | "),_c('a',{attrs:{"href":"https://codepen.io/MattIn4D"}},[_vm._v("Mattln4D")]),_c('br'),_c('strong',[_vm._v("Field Animations with CSS Custom Properties")]),_vm._v(" | "),_c('a',{attrs:{"href":"https://codepen.io/melnik909"}},[_vm._v("Stas Melnikov")]),_c('br'),_c('strong',[_vm._v("CSS Typing Effect")]),_vm._v(" | "),_c('a',{attrs:{"href":"https://codepen.io/denic"}},[_vm._v("Marko")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"text-center mt-5 mb-3"},[_c('strong',[_vm._v("Follow Me")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"text-center mt-5 mb-3"},[_c('strong',[_vm._v("Contact Me")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center"},[_vm._v("if you want to hire or have other interests please contact me via email: "),_c('strong',[_vm._v("supriatna.raisa.z@gmail.com")])])
}]

export { render, staticRenderFns }